<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 16 11" fill="none">
    <path d="M8 0C6.80363 0 5.81818 0.985447 5.81818 2.18182C5.81818 3.37819 6.80363 4.36364 8 4.36364C9.19637 4.36364 10.1818 3.37819 10.1818 2.18182C10.1818 0.985447 9.19637 0 8 0ZM2.90909 1.45455C2.52332 1.45455 2.15335 1.60779 1.88057 1.88057C1.60779 2.15335 1.45455 2.52332 1.45455 2.90909C1.45455 3.29486 1.60779 3.66483 1.88057 3.93761C2.15335 4.21039 2.52332 4.36364 2.90909 4.36364C3.29486 4.36364 3.66483 4.21039 3.93761 3.93761C4.21039 3.66483 4.36364 3.29486 4.36364 2.90909C4.36364 2.52332 4.21039 2.15335 3.93761 1.88057C3.66483 1.60779 3.29486 1.45455 2.90909 1.45455V1.45455ZM13.0909 1.45455C12.7051 1.45455 12.3352 1.60779 12.0624 1.88057C11.7896 2.15335 11.6364 2.52332 11.6364 2.90909C11.6364 3.29486 11.7896 3.66483 12.0624 3.93761C12.3352 4.21039 12.7051 4.36364 13.0909 4.36364C13.4767 4.36364 13.8466 4.21039 14.1194 3.93761C14.3922 3.66483 14.5455 3.29486 14.5455 2.90909C14.5455 2.52332 14.3922 2.15335 14.1194 1.88057C13.8466 1.60779 13.4767 1.45455 13.0909 1.45455V1.45455ZM2.90909 5.81818C1.32 5.81818 0 6.47912 0 7.4304V8.72727H2.18182V8.15767C2.18182 7.24494 2.56508 6.45632 3.2429 5.83523C3.13235 5.82868 3.02327 5.81818 2.90909 5.81818ZM8 5.81818C5.43127 5.81818 3.63636 6.78022 3.63636 8.15767V10.1818H12.3636V8.15767C12.3636 6.78022 10.5687 5.81818 8 5.81818ZM13.0909 5.81818C12.9767 5.81818 12.8676 5.82868 12.7571 5.83523C13.4349 6.45632 13.8182 7.24567 13.8182 8.15767V8.72727H16V7.4304C16 6.47912 14.68 5.81818 13.0909 5.81818Z"
    :fill="fill"/>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 16
    },
    height: {
      type: [Number, String],
      default: 11
    },
    fill: {
      type: String,
      default: '#9CA6BA'
    }
  }
}
</script>
